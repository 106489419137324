// LightControlButtons.tsx
import React, { useState } from 'react';
import * as ROSLIB from 'roslib';

interface LightControlButtonsProps {
  ros: ROSLIB.Ros;
}

const LightControlButtons: React.FC<LightControlButtonsProps> = ({ ros }) => {
  const [isFrontLightOn, setIsFrontLightOn] = useState(false);
  const [isRearLightOn, setIsRearLightOn] = useState(false);

  // Define ROS publishers
  const frontLightPublisher = new ROSLIB.Topic({
    ros: ros,
    name: '/front_light',
    messageType: 'std_msgs/Bool'
  });

  const rearLightPublisher = new ROSLIB.Topic({
    ros: ros,
    name: '/rear_light',
    messageType: 'std_msgs/Bool'
  });

  const handleFrontLight = () => {
    const newState = !isFrontLightOn;
    const message = new ROSLIB.Message({
      data: newState
    });
    frontLightPublisher.publish(message);
    setIsFrontLightOn(newState);
    console.log('Published to /front_light:', newState);
  };

  const handleRearLight = () => {
    const newState = !isRearLightOn;
    const message = new ROSLIB.Message({
      data: newState
    });
    rearLightPublisher.publish(message);
    setIsRearLightOn(newState);
    console.log('Published to /rear_light:', newState);
  };

  return (
    <div>
      <button 
        onClick={handleFrontLight} 
        style={{ backgroundColor: isFrontLightOn ? 'gray' : 'white' }}
      >
        {isFrontLightOn ? 'Front Light' : 'Front Light'}
      </button>
      <button 
        onClick={handleRearLight} 
        style={{ backgroundColor: isRearLightOn ? 'gray' : 'white' }}
      >
        {isRearLightOn ? 'Rear Light' : 'Rear Light'}
      </button>
    </div>
  );
};

export default LightControlButtons;
