import { useEffect, useState, createContext, FC, useContext, useRef } from "react";
import { Ros } from "roslib";
import { useRos } from "./utils/rosProvider";

interface IRosConnection {
    url : string;
    autoconnect : boolean;
    timeout : number;
}


const ConnectionContext = createContext<boolean>(false);

/**
 * Connection Provider for active ROS connection. Allows access to the useConnection hook within the provider. 
 * Used for determining if we have a connection to the robot or not.
 */
export const Connection : FC<IRosConnection> = ({children, url, autoconnect, timeout}) => {

    const ROS : Ros = useRos();     //The active ROS instance
    const autoConnectTimer = useRef<NodeJS.Timeout>(setTimeout(() => '', 10));  //Timer ref for auto-reconnection if a connection cannot be established.
    const isExiting = useRef<boolean>(false);                                   //Determines if we set the state on page close. If false set state otherwise don't becuase the page is exiting
    const [connected, setConnected] = useState<boolean>(false);                 //Connection state.

    useEffect(() => {

        const connect = (ros : Ros, url:string) => {
            ////console.log("Making connection: ");
            ros.connect(url);
        }
    
        const close = (ros : Ros) => {
            ros.close();
        }
    
        const setup = (ros : Ros, url : string) => {
    
            ros.on("connection", () => {
                console.log("Connection established");
                ////console.log("Is connected? ", ros.isConnected);
                setConnected(true);
            });
    
            ros.on("error", (error: any) => {
                console.error("Error During Ros Connection", error);
                ros.close();
            });
    
            ros.on("close", () => {
                ros.close();
                if(isExiting.current === false){
                    setConnected(false);
                }
                if(autoconnect){
                    autoConnectTimer.current = setTimeout(() => {
                        //console.log("Retrying...");
                        connect(ROS, url);
                    }, timeout);   //Attempt a reconnect after the client closes.
                }
                
            });
        }

        setup(ROS, url);
        connect(ROS, url);

        return () => {
            isExiting.current = true;
            clearTimeout(autoConnectTimer.current);
            close(ROS);
        }
    },[]);

    return (
        <ConnectionContext.Provider value={connected}>
            {children}
        </ConnectionContext.Provider>
    );
}


export const useConnection = () => {
    const context = useContext(ConnectionContext);
    if(context === undefined){
       throw new Error("This must be used inside a Connection element"); 
    }

    return context;
}