import { 
    memo, 
    useEffect, 
    useState, 
    MouseEventHandler 
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFetch } from '../../services/Requests/useFetch';
import { StopTravelControlBanner } from './StopTravelControlBanner';
import { ROBOT_URL, ROS_PORT } from '../../constants/constants';
import { Subscriber } from '../../services/Ros/Subscriber';
import { GPS_TOPIC, STATE } from '../../constants/topics';
import { StopTravelMap } from './StopTravelMap';
import { useRobotGPS } from '../../services/Ros/useRobotGPS';
import { RobotState } from '../../core/Messages/RobotStateMessage';

export interface StopData {
    stop: string;
    pathname: string;
    next: string;
    next_pathname: number;
    gps: number[];
    zone: string;
}

export interface IStopInfo {
    stops: StopData[];
}

export interface IZoneInfo  {
    zones: IStopInfo[];
}

interface IRunMission {
    t: string;
}

const options: RequestInit = {
    method: "GET"
}

interface StopTravelBodyProps {
    initialActiveIndex?: string;
    initialMissionInfo?: IStopInfo;
    initialLaunched?: boolean;
    setInitialActiveIndex: (value: string) => void;
    setInitialMissionInfo: (value: IStopInfo) => void;
    setInitialLaunched: (value: boolean) => void;
}

// Haversine formula to calculate the distance between two GPS points
interface GPSPoint {
    lat: number;
    long: number;
}

function haversineDistance(point1: GPSPoint, point2: GPSPoint): number {
    const toRadians = (degrees: number) => degrees * (Math.PI / 180);

    const R = 6371e3; // Earth's radius in meters
    const φ1 = toRadians(point1.lat);
    const φ2 = toRadians(point2.lat);
    const Δφ = toRadians(point2.lat - point1.lat);
    const Δλ = toRadians(point2.long - point1.long);

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // in meters

    return distance;
}

function arePointsClose(point1: GPSPoint, point2: GPSPoint, threshold: number): boolean {
    const distance = haversineDistance(point1, point2);
    return distance <= threshold;
}

export const StopTravelBodyMemo = ({
    initialActiveIndex = '',
    initialMissionInfo = { stops: [] },
    initialLaunched = false,
    setInitialActiveIndex,
    setInitialMissionInfo,
    setInitialLaunched
}: StopTravelBodyProps) => {
    const fetchMissionPlanData = useFetch<StopData[]>('/api/missions/get_stops_data', options);
    const runMissionPlanData = useFetch<IRunMission>('/api/missions/stop_to_home', options);
    const runGoToStop = useFetch<IRunMission>('/api/missions/go_to_stop', options);

    const [activeIndex, setActiveIndex] = useState<string>(initialActiveIndex);
    const [missionInfo, setMissionInfo] = useState<IStopInfo>(initialMissionInfo);
    const [activeStop, setActiveStop] = useState<StopData>();
    const [homeStop, setHomeStop] = useState<StopData>();
    const [launched, setLaunched] = useState<boolean>(initialLaunched);
    const [pointsAreClose, setPointsAreClose] = useState<boolean>(false)
    const [robotIsHome, setRobotIsHome] = useState<boolean>(false)
    const robotGPS = useRobotGPS()

    const handleSelected = (id: string) => {
        setActiveIndex(id);
        setInitialActiveIndex(id);
        
        // Split the id into zone and stop
        const [zone, stop] = id.split('/');
    
        // Find the matching stop data
        const selectedStop = missionInfo.stops.find((stopData) => 
            stopData.zone === zone && stopData.stop === stop
        );
    
        // If found, set the activeStop
        if (selectedStop) {
            setActiveStop(selectedStop);
        }
    
        console.log(selectedStop);
    }
    
    const handleRun: MouseEventHandler = (_e) => {
        if (activeIndex !== '') {
            const info = activeIndex.split('/');
            runMissionPlanData.get([
                { param: 'mission', value: 333 },
                { param: 'host', value: ROBOT_URL },
                { param: 'port', value: ROS_PORT },
                { param: 'stop', value: info[1] },
                { param: 'zone', value: info[0] }
            ]);
        }
        setLaunched(true);
        setInitialLaunched(true);
    }

    const handleRunStop: MouseEventHandler = (_e) => {
        if (activeIndex !== '') {
            const info = activeIndex.split('/');
            runGoToStop.get([
                { param: 'mission', value: 333 },
                { param: 'host', value: ROBOT_URL },
                { param: 'port', value: ROS_PORT },
                { param: 'stop', value: info[1] },
                { param: 'zone', value: info[0] }
            ]);
        }
        setLaunched(true);
        setInitialLaunched(true);
    }

    useEffect(() => {
        fetchMissionPlanData.get();

        return () => {
            fetchMissionPlanData.abortRequest && fetchMissionPlanData.abortRequest();
        }
    }, []);

    useEffect(() => {
        const { state: { status, data } } = fetchMissionPlanData;

        if (status === 'fetched' && data && data.length > 0) {
            console.log(data)
            console.log(data[0])
            setHomeStop(data[0])
            setMissionInfo({ stops: data });
            setInitialMissionInfo({ stops: data });
        }
    }, [fetchMissionPlanData.state]);

    useEffect(() => {
        setActiveIndex(initialActiveIndex);
        setMissionInfo(initialMissionInfo);
        setLaunched(initialLaunched);
    }, [initialActiveIndex, initialMissionInfo, initialLaunched]);

    const handleMarkerClick = (e: StopData) => {
        setActiveStop(e);
        setActiveIndex(`${e.zone}/${e.stop}`);
        setInitialActiveIndex(`${e.zone}/${e.stop}`);
    }

    useEffect(() => {
        if (robotGPS && activeStop) {
            const thresholdDistance = 2.5; // in meters
            const gpsPoint1: GPSPoint = { lat: activeStop.gps[0], long: activeStop.gps[1] }; // Example GPS point
            const gpsPoint2: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
            setPointsAreClose(arePointsClose(gpsPoint1, gpsPoint2, thresholdDistance));
        }

        if (robotGPS && homeStop){
            const thresholdDistance = 2.5;
            const gpsPoint1: GPSPoint = { lat: homeStop.gps[0], long: homeStop.gps[1] }; // Example GPS point
            const gpsPoint2: GPSPoint = { lat: robotGPS.lat, long: robotGPS.long }; // Example GPS point
            setRobotIsHome(arePointsClose(gpsPoint1, gpsPoint2, thresholdDistance));
        }
    }, [robotGPS, activeStop]);

    const handleRobotStateChange = (state: string) => {
        if (state === "READY" && launched) {
            setLaunched(false);
            setInitialLaunched(false);
        }
        if (state === "MANUAL") {
            setLaunched(true);
            setInitialLaunched(true);
        }
    }

    return (
        <Row className='h-100'>
            <Col lg={9} className="ps-0">
                <Subscriber name={GPS_TOPIC.name} type={GPS_TOPIC.type} rate={GPS_TOPIC.throttle}>
                    <StopTravelMap missionInfo={missionInfo} onMarkerClick={handleMarkerClick} activeIndex={activeIndex} />
                </Subscriber>
                <Subscriber name={STATE.name} type={STATE.type} rate={STATE.throttle}>
                    <RobotState name="robot_state" onStateChange={handleRobotStateChange} />
                </Subscriber>
            </Col>
            <Col lg={3} className="mt-3 mb-3 path-planning-column">
                <StopTravelControlBanner 
                    onSelect={handleSelected} 
                    onRun={handleRun}
                    onRunStop={handleRunStop}
                    selectedValue={activeIndex}
                    running={launched}
                    safe={pointsAreClose}
                    home={robotIsHome}           
                />
            </Col>
        </Row>
    );
}

export const StopTravelBody = memo(StopTravelBodyMemo);
