import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { StopSelect } from './StopSelect';


interface Props {
    onSelect : (id : string) => void;       //Callback to handle the selection of a mission plan.
    onRun : MouseEventHandler;              //Callback to run to run the mission plan.
    onRunStop : MouseEventHandler
    selectedValue? : string;
    running : boolean;
    safe: boolean;
    home:boolean;
}

//@ts-ignore
export const StopTravelControlBanner = ({ onSelect, onRun, onRunStop, selectedValue, running,safe, home } : Props) => {
    // console.log(selectedValue)
    return (
        <div className='mission-planning-banner p-3 mb-4 bg-white rounded' >
            <span className='text-dark h5'>Stop Select</span>
            <StopSelect onSelect={onSelect} selectedValue={selectedValue}/>
            <div className='d-flex'>
                <Button variant="primary" onClick={onRun} disabled={running || selectedValue === '' || !safe} className="flex-fill me-2">
                    Go Home via Stop
                </Button>
                <Button variant="primary" onClick={onRunStop} disabled={running || selectedValue === '' || !home} className="flex-fill me-2">
                    Go to Stop from Home
                </Button>
            </div>
        </div>
    );
}