import { memo } from 'react';
import '../../css/CollisionOverlay.css';
import { useMsg } from "../../services/Ros/Subscriber";

interface CollisionMessage {
    data: boolean;
}

// Example of how CollisionOverlay might be defined
interface CollisionOverlayProps {
    direction: string;
    stream: string;
  }
  
const parseMessage = (message: CollisionMessage) => {

    return message;
}

const CollisionOverlayMemo: React.FC<CollisionOverlayProps> = ({ direction,stream })  => {
    const msg = useMsg();

    const  parsedCollisionMessage = parseMessage(msg as CollisionMessage);
    let showCollision= false;
    let collisionMessage = "";
    let backgroundColor = "";
    let position: string = 'top';

    const collisionDirection = direction

    if (stream === 'front' || stream === 'ptz'){
        if (collisionDirection === "Front"){
            position = 'top'
        }else{
            position = 'bottom'
        }
    }else if (stream === 'rear'){
        if (collisionDirection === "Front"){
            position = 'bottom'
        }else{
            position = 'top'
        }
    }

    if (parsedCollisionMessage.data) {
        showCollision = true;
        collisionMessage = `Collision Detected ${collisionDirection}`;
        backgroundColor = "rgba(255, 0, 0, 0.7)"; // Red
    } else {
        showCollision = false;
    } 

    return (
        <>
            {showCollision && (
                <div className={position} style={{ backgroundColor }}>
                    <p>{collisionMessage}</p>
                </div>
            )}
        </>
    );
};

export const CollisionOverlay = memo(CollisionOverlayMemo);
