import { TopicInterface } from "../interfaces/Common";

export enum MessageTypes {
    BATTERY = "/battery_state",
    DIAGNOSTICS = "/diagnostics",
    // GPS = "/navsat/fix",
    GPS = "/gps_handler/fix",
    //Stamped CMD VEL
    TEST = "/cmd_vel_dashboard",
    STAMPED = "/cmd_vel_override",
    // Unstamped CDM VEL
    // TEST = "/cmd_vel_teleop",
    // STAMPED = "/cmd_vel",
    EDRIVING = "/cmd_vel",
    AUTOMATED = "/joy",
    COMPASS = "/gps/compass",
    WARN = "/keepout_zone_alert",
    FRONT_LIGHTS = "/front_light",
    REAR_LIGHTS = "/rear_light",
    STATE = "/robot_state",
    CARDINAL = "/gps/cardinal",
    ESTOP = "/kill_switch",
    FRONT_COLLISION = "/obstacle_front",
    REAR_COLLISION = "/obstacle_rear"
};

export const STAMPED: TopicInterface = { 
    key : 'drive',
    name : MessageTypes.STAMPED,
    type : "geometry_msgs/TwistStamped",
    throttle : 1000
}

export const FRONT_COLLISION: TopicInterface = { 
    key : 'collison',
    name : MessageTypes.FRONT_COLLISION,
    type : "std_msgs/Bool",
    throttle : 1000
}

export const REAR_COLLISION: TopicInterface = { 
    key : 'collison',
    name : MessageTypes.REAR_COLLISION,
    type : "std_msgs/Bool",
    throttle : 1000
}

export const ESTOP: TopicInterface = { 
    key : 'ESTOP',
    name : MessageTypes.ESTOP,
    type : "std_msgs/Bool",
    throttle : 1000
}

export const STATE: TopicInterface = { 
    key : 'robot',
    name : MessageTypes.STATE,
    type : "std_msgs/UInt8",
    throttle : 1000
}

export const CARDINAL: TopicInterface = { 
    key : 'cardinal',
    name : MessageTypes.CARDINAL,
    type : "std_msgs/String",
    throttle : 1000
}

export const FRONT_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.FRONT_LIGHTS,
    type : "std_msgs/Bool",
    throttle : 1000
}

export const REAR_LIGHT_TOPIC: TopicInterface = { 
    key : 'lights',
    name : MessageTypes.REAR_LIGHTS,
    type : "std_msgs/Bool",
    throttle : 1000
}

export const WARN_TOPIC: TopicInterface = { 
    key : 'warn',
    name : MessageTypes.WARN,
    type : "std_msgs/Int32",
    throttle : 1000
}

export const TEST_TOPIC: TopicInterface = { 
    key : 'test',
    name : MessageTypes.TEST,
    type : "geometry_msgs/TwistStamped",
    throttle : 1000
}

export const BATTERY_TOPIC: TopicInterface = {
    key : 'battery',
    name : MessageTypes.BATTERY,
    type : "sensor_msgs/BatteryState",
    throttle : 5000
};

export const DIAGNOSTICS_TOPIC: TopicInterface = {
    key : 'diagnostics',
    name : MessageTypes.DIAGNOSTICS,
    type : "diagnostic_msgs/DiagnosticArray", 
    // throttle : 5010
}

export const GPS_TOPIC: TopicInterface = {
    key : 'gps',
    name : MessageTypes.GPS,
    type : "sensor_msgs/NavSatFix",
    throttle : 1000
};

export const COMPASS_TOPIC : TopicInterface = {
    key : 'compass',
    name : MessageTypes.COMPASS,
    type : "std_msgs/UInt16",
    throttle : 1000
}

export const EDRIVING : TopicInterface = {
    key : 'EDRIVING',
    name : MessageTypes.EDRIVING,
    type : "geometry_msgs/TwistStamped",
    throttle : 1000
}

export const AUTOMATED_DRIVE : TopicInterface = {
    key : 'automated',
    name : MessageTypes.AUTOMATED,
    type : "sensor_msgs/Joy"
}



