import React, { useEffect, useRef } from 'react';
import '../../css/RTSPViewer.css';

interface WebRTCVideoProps {
  server: string;
  src: string;
  useMSE?: boolean; // Add a prop to indicate if MSE should be used
}

const WebRTCVideo: React.FC<WebRTCVideoProps> = ({ server, src}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const pcConfig: RTCConfiguration = {
    bundlePolicy: 'max-bundle' as RTCBundlePolicy,
    iceServers: [
        { urls: "stun:stun.l.google.com:19302" },
        {
            urls: "turns:turn.wrybillrobotics.com:3478",
            username: "wrybill",
            credential: "wryb1llr0b0t1cs"
        },
    ],
    iceCandidatePoolSize: 10
}



  useEffect(() => {
      // WebRTC logic
      const connectWebRTC = async (media: string) => {
        try {
          
          const pc = new RTCPeerConnection(
            pcConfig
          );
          console.log('Media:', media);

          const localTracks: MediaStreamTrack[] = [];

          const getMediaTracks = async (
            mediaType: 'user' | 'display',
            constraints: MediaStreamConstraints
          ): Promise<MediaStreamTrack[]> => {
            try {
              const stream =
                mediaType === 'user'
                  ? await navigator.mediaDevices.getUserMedia(constraints)
                  : await navigator.mediaDevices.getDisplayMedia(constraints);
              return stream.getTracks();
            } catch (e) {
              console.warn('Error getting media tracks:', e);
              return [];
            }
          };

          if (/camera|microphone/.test(media)) {
            const tracks = await getMediaTracks('user', {
              video: media.includes('camera'),
              audio: media.includes('microphone'),
            });
            tracks.forEach((track) => {
              pc.addTransceiver(track, { direction: 'sendonly' });
              if (track.kind === 'video') localTracks.push(track);
            });
          }

          if (media.includes('display')) {
            const tracks = await getMediaTracks('display', {
              video: true,
              audio: media.includes('speaker'),
            });
            tracks.forEach((track) => {
              pc.addTransceiver(track, { direction: 'sendonly' });
              if (track.kind === 'video') localTracks.push(track);
            });
          }

          const tracks = ['video', 'audio']
            .filter((kind) => media.includes(kind))
            .map((kind) =>
              pc.addTransceiver(kind, { direction: 'recvonly' }).receiver.track
            );
          localTracks.push(...tracks);

          console.log('PeerConnection:', pc);

          if (videoRef.current) {
            videoRef.current.srcObject = new MediaStream(localTracks);
          }

          const url = new URL(`api/ws?src=${media}`, server);
          const wsUrl = 'ws' + url.toString().substring(4);
          console.log('WebSocket URL:', wsUrl);
          const ws = new WebSocket(wsUrl);

          ws.addEventListener('open', () => {
            console.log('WebSocket connection opened');
            pc.addEventListener('icecandidate', (ev) => {
              if (!ev.candidate) return;
              const msg = { type: 'webrtc/candidate', value: ev.candidate.candidate };
              // console.log(msg)
              ws.send(JSON.stringify(msg));
            });

            pc.createOffer()
              .then((offer) => pc.setLocalDescription(offer))
              .then(() => {
                
                const msg = { type: 'webrtc/offer', value: pc.localDescription!.sdp };
                ws.send(JSON.stringify(msg));
                // console.log(msg)
              })
              .catch((error) => {
                console.error('Error creating or setting local offer:', error);
              });
          });

          ws.addEventListener('message', (ev) => {
            const msg = JSON.parse(ev.data);
            // console.log(msg);
            if (msg.type === 'webrtc/candidate') {
              pc.addIceCandidate({ candidate: msg.value, sdpMid: '0' }).catch((error) => {
                console.error('Error adding ICE candidate:', error);
              });
            } else if (msg.type === 'webrtc/answer') {
              pc.setRemoteDescription({ type: 'answer', sdp: msg.value }).catch((error) => {
                console.error('Error setting remote description:', error);
              });
            }
          });

          ws.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
          });

          ws.addEventListener('close', () => {
            console.log('WebSocket connection closed');
          });
        } catch (error) {
          console.error('Error in connect function:', error);
        }
      };

      connectWebRTC(src + '&media=video' || 'video+audio');
  }, [server, src]);

  return <div className="fullscreen-video"><video id="video" ref={videoRef} autoPlay controls muted /></div>;
};

export default WebRTCVideo;
