import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import userManager from './userManager';
import { AUTHREQ, OPERATOR_STATUS } from '../../../constants/constants';
import { useObserver } from './ObserverContext';
import { useOperator } from './OperatorContext';


export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {isOperator, setIsOperator} = useOperator();
  const { isObserver, setIsObserver } = useObserver();  // Get the observer state


  useEffect(() => {
    console.log("Checking user authentication status...");
    if (AUTHREQ){
      userManager.getUser().then(user => {
        if (user) {
          console.log("User is authenticated", user);
          const observerClaims = user.profile['groups'];
          if (observerClaims.includes('Observer')){
            console.log("User is Observer");
            setIsObserver(true);  // Update the observer status in the context
          } else {
            setIsObserver(false);
          }
          if (observerClaims.includes('AdvancedOperator')){
            console.log("User is AdvancedOperator");
            setIsOperator(true);  // Update the observer status in the context
          } else {
            setIsOperator(false);
          }
          // console.log(`Observer claims: ${observerClaims}`)
          setIsAuthenticated(true);
        } else {
          console.log("User is not authenticated, redirecting...");
          // userManager.signinRedirect({ state: { targetUrl: rest.location?.pathname } });
          userManager.removeUser().then(() => {
            userManager.signinRedirect({ state: { targetUrl: rest.location?.pathname } });
          });
        }
        setIsLoading(false);
      }).catch(err => {
        console.error("Error during user authentication check", err);
        setIsLoading(false);
      });
    }else{
      console.log(`Authentication required set to ${AUTHREQ}`)
      setIsAuthenticated(true);

    }
  }, [rest.location?.pathname]);
  

  useEffect(() => {
    // This effect checks the authentication status to avoid re-triggering the redirect
    if (isAuthenticated) {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null; // Or a loading spinner if preferred
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired,
};
